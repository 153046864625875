<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <specialities-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import SpecialitiesIndex from "../../../../components/admin/Dictionaries/Specialities/SpecialitiesIndex";
    export default  {
        components: {
            SpecialitiesIndex,
        }
    }
</script>