<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.nameUk" v-model="form.nameUk"
                                          label="Назва (укр)"></v-text-field>
                        </v-flex>
<!--                        <v-flex xs12>-->
<!--                            <v-text-field :error-messages="errors.nameEn" v-model="form.nameEn"-->
<!--                                          label="Назва (eng)"></v-text-field>-->
<!--                        </v-flex>-->
                        <v-flex xs12 md4>
                            <v-text-field :error-messages="errors.nameShortUk" v-model="form.nameShortUk"
                                          label="Скорочено (укр)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field :error-messages="errors.nameShortEn" v-model="form.nameShortEn"
                                          label="Скорочено (eng)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field :error-messages="errors.orderBy" v-model="form.orderBy" v-mask="['##']"
                                          label="Порядок"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-select
                                    :items="faculties"
                                    :error-messages="errors.faculties"
                                    v-model="form.faculties"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Факультети"
                                    multiple
                                    chips
                            ></v-select>
                        </v-flex>

                        <v-flex xs12 md12>
                            <v-select
                                    :items="semesters"
                                    :error-messages="errors.semesters"
                                    v-model="form.semesters"
                                    item-text="value"
                                    item-value="id"
                                    return-object
                                    label="Семестри"
                                    multiple
                                    chips
                                    append-icon="control_point"
                                    @click:append="selectAllSpecialities"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    nameUk: null,
                    nameEn: null,
                    nameShortUk: null,
                    nameShortEn: null,
                    orderBy: null,
                    faculties: [],
                    semesters: [],
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    nameShortUk: [],
                    nameShortEn: [],
                    orderBy: [],
                    faculties: [],
                    semesters: [],
                },
                faculties: [],
                semesters: [],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.faculties = resp;
                });

            HttpService.getInstance()
                .get("semester/semester")
                .then(resp => {
                    this.semesters = resp.map(s => {
                        s.value = `${s.yearNumber} курc (${s.semesterNumber} семестр)`;
                        return s;
                    });
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`specialities/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            selectAllSpecialities() {
                this.form.semesters = this.semesters;
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("specialities", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>