<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <faculties-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import FacultiesIndex from "../../../../components/admin/Dictionaries/Faculties/FacultiesIndex";
    export default  {
        components: {
            FacultiesIndex,
        }
    }
</script>