<template>
    <div>
        <departments-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </departments-grid>

        <v-dialog v-model="dialog" max-width="500px" scrollable>
            <add-edit-department
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-department>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import DepartmentsGrid from "./DepartmentsGrid";
    import { objectQueryStringify } from "../../../../helpers/querystringHelper";
    import AddEditDepartment from "./AddEditDepartment";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            DepartmentsGrid,
            AddEditDepartment,
            DeleteConfirm
        },
        mounted() {
            if (this.$route.params.facultyId !== undefined) {
                let filter = [{field: 'facultyId', value: this.$route.params.facultyId, operator: 'eq'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
            }
            if (this.$route.params.departmentType !== undefined) {
                let filter = [{field: 'departmentType', value: this.$route.params.departmentType, operator: 'eq'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
            }
        },
        watch: {
            '$route.params.facultyId': function (newFacultyId) {
                if (newFacultyId !== undefined) {
                    let filter = [{field: 'facultyId', value: newFacultyId, operator: 'eq'}];

                    this.pagination = Object.assign({}, this.pagination, {filter});

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            '$route.params.departmentType': function (newDepartmentType) {
                if (newDepartmentType !== undefined) {
                    let filter = [{field: 'departmentType', value: newDepartmentType, operator: 'eq'}];

                    this.pagination = Object.assign({}, this.pagination, {filter});

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            '$route.params': function (newParams, oldParams) {
                if (!newParams.facultyId && !newParams.departmentType) {
                    this.getItems(objectQueryStringify(this.pagination));
                }
            }
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`departments?${queryParams}`)
                    .then(departments => {
                        this.items = departments.items;
                        this.total = departments.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>