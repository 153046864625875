export const departmentTypesEnum = {
    theoretical: 0,
    clinical: 1,
};

export default [
    {
        typeEnum: departmentTypesEnum.theoretical,
        nameConst: "Теоретичні"
    },
    {
        typeEnum: departmentTypesEnum.clinical,
        nameConst: "Клінічні"
    },
];
